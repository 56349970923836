<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          placeholder="请输入姓名"
          label="姓名"
          v-model="searchParam.name"
        />
        <v-input
          placeholder="请输入联系电话"
          label="联系电话"
          v-model="searchParam.mobileNum"
        />
        <v-input
          placeholder="请输入所属支部"
          label="所属支部"
          v-model="searchParam.belongBranch"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDel(scope.row)" />
      </template>
    </v-list>
    <el-dialog
      :title="title"
      :visible.sync="show"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px">
        <el-form-item label="姓名">
          <v-input
            placeholder="请输入姓名"
            v-model="tempObj.name"
            :width="400"
          />
        </el-form-item>
        <el-form-item label="手机号">
          <v-input
            placeholder="请输入手机号"
            v-model="tempObj.mobileNum"
            :width="400"
          />
        </el-form-item>
        <el-form-item label="图片">
          <v-upload
            :activityPicture.sync="tempObj.avatarUrl"
            ref="load"
            :limit="1"
            :imgUrls.sync="tempObj.avatarUrl"
          />
        </el-form-item>
        <el-form-item label="排序">
          <v-input
            placeholder="请输入排序"
            v-model="tempObj.sortIndex"
            :width="400"
          />
        </el-form-item>
        <el-form-item label="职务">
          <v-input
            placeholder="请输入职务"
            v-model="tempObj.position"
            :width="400"
          />
        </el-form-item>
        <el-form-item label="所属支部">
          <v-input
            placeholder="请输入所属支部"
            v-model="tempObj.belongBranch"
            :width="400"
          />
        </el-form-item>
        <el-form-item label="网格管理">
          <v-input
            placeholder="请输入网格管理"
            v-model="tempObj.griddingArea"
            :width="400"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button size="medium" @click="show = false" text="取消"></v-button>
        <v-button
          size="medium"
          type="primary"
          @click="submit"
          text="确定"
        ></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createImgVNode } from "@/utils/utils.js";
import {
  getInfoDetailsUrl,
  addOrUpdateInfoUrl,
  deleteInfoUrl,
  getListUrl,
} from "./api.js";
export default {
  name: "sinkPartyMember",
  data() {
    return {
      show: false,
      title: "",
      searchParam: {
        name: null,
        mobileNum: null,
        belongBranch: null,
      },
      tempObj: {
        id: null,
        name: null,
        mobileNum: null,
        avatarUrl: null,
        sortIndex: null,
        position: null,
        belongBranch: null,
        griddingArea: null,
      },
      tableUrl: getListUrl,
      headers: [
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "avatarUrl",
          label: "照片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "mobileNum",
          label: "电话",
        },
        {
          prop: "position",
          label: "职务",
        },
        {
          prop: "belongBranch",
          label: "所属支部",
        },
        {
          prop: "griddingArea",
          label: "网格管理区域",
        },
        {
          prop: "sortIndex",
          label: "排序",
        },
      ],
    };
  },
  methods: {
    toAdd() {
      this.title = "新增";
      this.tempObj.id = null;
      this.tempObj.name = null;
      this.tempObj.mobileNum = null;
      this.tempObj.avatarUrl = null;
      this.tempObj.sortIndex = null;
      this.tempObj.position = null;
      this.tempObj.belongBranch = null;
      this.tempObj.griddingArea = null;
      this.show = true;
    },
    toEdit(item) {
      this.title = "编辑";
      this.tempObj.id = item.id;
      this.tempObj.name = item.name;
      this.tempObj.mobileNum = item.mobileNum;
      this.tempObj.avatarUrl = item.avatarUrl;
      this.tempObj.sortIndex = item.sortIndex;
      this.tempObj.position = item.position;
      this.tempObj.belongBranch = item.belongBranch;
      this.tempObj.griddingArea = item.griddingArea;
      this.show = true;
    },
    toDel(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${deleteInfoUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    submit() {
      if (this.tempObj.name == "" || this.tempObj.name == null) {
        this.$message.error("请输入姓名！");
        return;
      }
      if (this.tempObj.mobileNum == "" || this.tempObj.mobileNum == null) {
        this.$message.error("请输入手机号！");
        return;
      }
      if (this.tempObj.avatarUrl == "" || this.tempObj.avatarUrl == null) {
        this.$message.error("请上传图片！");
        return;
      }
      if (this.tempObj.sortIndex == "" || this.tempObj.sortIndex == null) {
        this.$message.error("请输入排序！");
        return;
      }
      if (this.tempObj.position == "" || this.tempObj.position == null) {
        this.$message.error("请输入职务！");
        return;
      }
      if (
        this.tempObj.belongBranch == "" ||
        this.tempObj.belongBranch == null
      ) {
        this.$message.error("请输入所属支部！");
        return;
      }
      if (
        this.tempObj.griddingArea == "" ||
        this.tempObj.griddingArea == null
      ) {
        this.$message.error("请输入网格管理！");
        return;
      }
      let params = this.tempObj;
      this.$axios.post(`${addOrUpdateInfoUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.show = false;
          this.$message.success("操作成功");
          this.$refs.list.search();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
